import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  USE_DROPDOWN_TPA_LABEL: 'specs.stores.UseDropdownTpaLabel',
  CART_TOOLTIP_WITHOUT_NUMBER: 'specs.stores.CartTooltipWithoutNumber',
  EDITOR_OOI: 'specs.stores.EditorGalleryOOI',
  ACTIVE_DISABLED_VARIANTS: 'specs.stores.ActiveDisabledVariants',
  DISABLE_MOBILE_NATIVE_DROPDOWN: 'specs.stores.DisableMobileNativeDropdown',
  FIX_CASHIER_PROPS_PRICE_SHIFTING: 'specs.stores.ProductPageFixCashierPropsPriceShifting',
  UseNewCheckoutInFastFlow: 'specs.stores.UseNewCheckoutInFastFlow',
  UseNewCheckoutInVisitorPickup: 'specs.stores.UseNewCheckoutInVisitorPickup',
  UseNewCheckoutInVisitorShippingAndDigitalFlows: 'specs.stores.UseNewCheckoutInVisitorShippingAndDigitalFlows',
  UseNewCheckoutInRestOfTheFlows: 'specs.stores.UseNewCheckoutInRestOfTheFlows',
  UseNewExpressCheckoutButtonsInProductPage: 'specs.stores.UseNewExpressCheckoutButtonsInProductPage',
  AddReviewsSlotsToProductPage: 'specs.stores.addReviewsSlotsToProductPage',
  ProductPageWishlistWithNewMembersArea: 'specs.stores.ProductPageWishlistWithNewMembersArea',
  SHOULD_USE_COMMON_DISCOUNT_PRICING_METHODS: 'specs.stores.ShouldUseCommonDiscountPricingMethods',
  LOCATION_ONCHANGE_PATH_CHANGE_FOR_EDITOR_SAUSAGE:
    'specs.stores.ProductPageLocationOnChangePathChangeForEditorSausage',
  DROPDOWN_LABEL_FOR: 'specs.stores.ProductPageDropdownLabelFor',
  PRODUCT_PAGE_RICO_DESCRIPTION: 'specs.stores.ProductPageRicoDescription',
  PRODUCT_PAGE_NAVIGATE_TO_HOME_PAGE_UPON_BUY_NOW_CART_CREATE_ERROR:
    'specs.stores.ProductPageNavigateToHomePageUponBuyNowCartCreateError',
  NotifyEmailOnCheckout: 'specs.stores.ProductPageNotifyEmailOnCheckout',
  SHOULD_USE_VARIANT_LEVEL_PRE_ORDER_AVAILABLE: 'specs.stores.ShouldUseVariantLevelPreOrderAvailable',
  SHOULD_RESET_QUANTITY_UPON_SELECTION_CHANGE: 'specs.stores.GalleryProductItemResetQuantityUponSelectionChange',
} as const;
